export const getVacancyIdFromPath = (path) => {
  const captureNumericId = /\/(\d+)(\/|$)/
  const match = path.match(captureNumericId)
  if (match && match[1]) {
    return match[1]
  }
}

// TODO Replace with a block in storyblok that takes care of getting its own token from the queryparms instead of passing it down the context.
export const getJobAlertTokenFromPath = (path) => {
  const url = new URL(path, 'http://localhost') // We don't care about the origin
  if (url.pathname.includes('/unsubscribe')) {
    return url.searchParams.get('token')
  }

  return null
}
